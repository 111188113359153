<template>
  <b-container
    fluid
    class="px-0 ml-1"
  >
    <b-row
      align-h="between"
    >

      <!-- #region begin::Product details title -->
      <b-col cols="6">
        <TitleDetails
          :id="product.IdProduct"
          :name="product.Name"
          :type="product.Type"
          :count="product.Qty"
        />
      </b-col>
      <!-- #endregion end::Product details title -->

      <b-col cols="6">
        <b-container fluid>
          <b-row
            align-h="end"
            align-v="center"
          >

            <!-- #region::Add pieces to circuits buttons -->
            <template v-if="showAddPiecesButtons">

              <!-- #region begin::Add selected pieces button -->
              <b-button
                v-if="canAddSelectedPieces"
                variant="principal-btn"
                class="principal-btn"
                @click="onAddSelectedPieces()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Agregar piezas seleccionadas</span>
              </b-button>
              <!-- #endregion end::Add selected pieces button -->

              <!-- #region begin::Add all pieces button -->
              <b-button
                v-else
                variant="principal-btn"
                class="principal-btn"
                @click="onAddAllPieces()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Agregar todas las piezas</span>
              </b-button>
              <!-- #endregion end::Add all pieces button -->

            </template>
            <!-- #endregion::Add pieces to circuits buttons -->

            <!-- #region::Delete pieces buttons -->
            <template v-if="showDeletePiecesButtons">

              <!-- #region::Delete selected pieces button -->
              <b-button
                v-if="canDeleteSelectedPieces"
                variant="delete-btn"
                class="delete-btn"
                @click="onDeleteSelectedPieces()"
              >
                <feather-icon
                  icon="MinusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Quitar piezas seleccionadas</span>
              </b-button>
              <!-- #endregion::Delete selected pieces button -->

              <!-- #region::Delete all pieces button -->
              <b-button
                v-else
                variant="delete-btn"
                class="delete-btn"
                @click="onDeleteAllPieces()"
              >
                <feather-icon
                  icon="MinusIcon"
                  class="mr-50"
                />
                <span class="align-middle">Quitar todas las piezas</span>
              </b-button>
              <!-- #endregion::Delete all pieces button -->

            </template>
            <!-- #endregion::Delete pieces buttons -->

            <!-- #region begin::Transferable pieces button -->
            <b-button
              v-if="showTransferPiecesButton"
              variant="principal-btn"
              class="principal-btn"
              @click="onTransferPieces()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Asignar piezas</span>
            </b-button>
            <!-- #endregion end::Transferable pieces button -->

            <!-- #region::Add pieces to circuit button -->
            <b-button
              v-if="showAddToCircuitButton && product.pieces && $ability.can('update', 'Circuit')"
              variant="flat-primary"
              class="my-1 my-lg-0"
              :disabled="!canAddSelectedPieces"
              @click="onShowCircuitsModal()"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Agregar a un circuito</span>
            </b-button>
            <!-- #endregion::Add pieces to circuit button -->

            <!-- #region begin::Delete product button -->
            <TransferPiecesButton
              v-if="showDeleteButton && $ability.can('delete', 'Product')"
              :product="product"
              @reload-list="reloadList()"
              @transfer-pieces="transferPieces()"
            />
            <!-- #endregion end::Delete product button -->

            <!-- #region begin::Edit product button -->
            <b-button
              v-if="showEditButton && $ability.can('edit', 'Product')"
              v-b-tooltip.hover.top="'Editar'"
              variant="flat-secondary"
              class="btn-icon px-2"
              @click="$router.push({ name: 'update-product', params: { id: product.IdProduct }})"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <!-- #endregion end::Edit product button -->

          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <!-- #region::Product details -->
    <BasicDetails
      :product="product"
      :icon-toggle="iconToggle"
      :visible="visible"
      @toggle-collapse="collapse"
    />
    <!-- #endregion::Product details -->

    <CircuitsModal
      v-if="showAddToCircuitButton"
      ref="circuitsModal"
      @reload-list="reloadList"
    />
  </b-container>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BContainer, BButton, VBTooltip,
} from 'bootstrap-vue'

import CircuitsModal from '@/modules/production/circuits/components/CircuitsModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TitleDetails from '@/modules/store/incomes/components/product-card/TitleDetails.vue'
import BasicDetails from '@/modules/store/incomes/components/product-card/BasicDetails.vue'
import TransferPiecesButton from '@/modules/production/products/components/modals/TransferPiecesButton.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BButton,
    BasicDetails,
    TitleDetails,
    CircuitsModal,
    TransferPiecesButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    collapseItemId: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    originProductId: {
      type: Number,
      required: true,
    },
    // * Card buttons
    showTransferPiecesButton: {
      type: Boolean,
      default: false,
    },
    showAddToCircuitButton: {
      type: Boolean,
      default: false,
    },
    showAddPiecesButtons: {
      type: Boolean,
      default: false,
    },
    showDeletePiecesButtons: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getLoadedPieces: 'circuits/getLoadedPieces',
      getCircuitPieces: 'circuits/getCircuitPieces',
      getProvitionalProducts: 'circuits/getProvitionalProducts',
      getTransferableProductId: 'products/getTransferableProductId',
      getProvitionalProductsToDelete: 'circuits/getProvitionalProductsToDelete',
    }),
    transferableProductId: {
      get() { return this.getTransferableProductId },
      set(value) { this.setTransferableProductId(value) },
    },
    provitionalProducts: {
      get() { return this.getProvitionalProducts },
    },
    circuitPieces: {
      get() { return this.getCircuitPieces },
    },
    provitionalProductsToDelete: {
      get() { return this.getProvitionalProductsToDelete },
    },
    loadedPieces: {
      get() { return this.getLoadedPieces },
    },
    isHomePage() {
      if (this.$route.name === 'products-home') {
        return true
      }
      return false
    },
    canAddSelectedPieces() {
      if (!this.provitionalProducts.length > 0) {
        return false
      }

      const existsAtIndex = this.provitionalProducts.findIndex(p => p.IdProduct === this.product.IdProduct)

      if (existsAtIndex === -1) {
        return false
      }

      if (this.product.pieces.length === this.provitionalProduct.pieces.length && !this.showAddToCircuitButton) {
        return false
      }

      return true
    },
    provitionalProduct() {
      return this.provitionalProducts.find(p => p.IdProduct === this.product.IdProduct)
    },
    canDeleteSelectedPieces() {
      if (!this.provitionalProductsToDelete > 0) {
        return false
      }

      if (!this.provitionalProductToDelete || this.provitionalProductToDelete.pieces.length === 0) {
        return false
      }

      const existsAtIndex = this.provitionalProductsToDelete.findIndex(p => p.IdProduct === this.product.IdProduct)

      if (existsAtIndex === -1) {
        return false
      }

      if (this.product.pieces.length === this.provitionalProductToDelete.pieces.length) {
        return false
      }
      return true
    },
    provitionalProductToDelete() {
      return this.provitionalProductsToDelete.find(p => p.IdProduct === this.product.IdProduct)
    },
  },
  created() {
    this.visible = this.isVisible
    this.setToggleIcon()
  },
  methods: {
    ...mapActions({
      deleteProduct: 'products/deleteProduct',
      addCircuitProduct: 'circuits/addCircuitProduct',
      addDeletedProduct: 'circuits/addDeletedProduct',
      setTransferableProductId: 'products/setTransferableProductId',
      addProvitionalProductToDelete: 'circuits/addProvitionalProductToDelete',
      deleteProductAndTransferPieces: 'products/deleteProductAndTransferPieces',
    }),
    collapse() {
      if (!this.visible) {
        this.$emit('rendering', true)
      }
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    async transferPieces() {
      this.$swal({
        title: 'Espere por favor',
        allowOutsideClick: false,
      })

      this.$swal.showLoading()

      try {
        await this.deleteProductAndTransferPieces({
          from: this.isHomePage ? this.product.IdProduct : this.transferableProductId,
          to: this.isHomePage ? this.transferableProductId : this.product.IdProduct,
        })

        this.$swal.fire('Piezas transferidas', '¡Las piezas se han transferido correctamente!', 'success')

        if (this.isHomePage) {
          this.$emit('reload-list')
        } else {
          this.$router.push({ name: 'products-home' })
        }
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },
    onTransferPieces() {
      this.$emit('hide-modal')
      if (this.isHomePage) {
        this.transferableProductId = this.product.IdProduct
        this.$emit('transfer-pieces')
      } else {
        this.transferableProductId = this.originProductId
        this.transferPieces()
      }
    },
    onAddSelectedPieces() {
      if (this.provitionalProduct) {
        this.addPiecesToCircuit()
      }
    },
    onDeleteSelectedPieces() {
      if (this.provitionalProductToDelete) {
        this.deletePiecesFromCircuit()
      }
    },
    onAddAllPieces() {
      if (this.provitionalProduct) {
        this.addPiecesToCircuit()
      } else {
        let sameCircuitLocation = false

        if (this.circuitPieces.length > 0) {
          const isSameCircuitLocation = piece => piece.Location === this.circuitPieces[0].Location
          sameCircuitLocation = this.product.pieces.every(isSameCircuitLocation)
        } else {
          sameCircuitLocation = true
        }

        const isSameProductLocation = piece => piece.Location === this.product.pieces[0].Location
        const sameProductLocation = this.product.pieces.every(isSameProductLocation)

        let sameLocationLoadedList = true

        if (this.loadedPieces.length > 0 && this.circuitPieces.length > 0) {
          const isSameLocationLoadedList = piece => piece.Location === this.loadedPieces[0].Location
          sameLocationLoadedList = this.product.pieces.every(isSameLocationLoadedList)
        }

        if (sameProductLocation && sameCircuitLocation && sameLocationLoadedList) {
          const circuitProduct = { ...this.product }
          circuitProduct.pieces = this.product.pieces.map(piece => ({ ...piece }))

          for (let index = 0; index < circuitProduct.pieces.length; index += 1) {
            circuitProduct.pieces[index].vgtSelected = false
          }

          this.$emit('select-all-rows')

          circuitProduct.Qty = circuitProduct.pieces.length
          this.addCircuitProduct(circuitProduct)

          this.showToast(
            'Piezas en circuito',
            'Las piezas del producto se han agregado al circuito.',
            'success',
          )
        } else {
          this.showToast(
            'Ubición incorrecta',
            'Las piezas del producto no pertenecen a la misma ubicación.',
            'warning',
          )
        }
      }
    },
    onDeleteAllPieces() {
      if (this.provitionalProductToDelete) {
        this.deletePiecesFromCircuit()
      } else {
        const circuitProductToDelete = { ...this.product }
        circuitProductToDelete.pieces = this.product.pieces.map(piece => ({ ...piece }))
        this.addDeletedProduct(circuitProductToDelete)

        const circuitProduct = { ...this.product }
        circuitProduct.pieces = []
        this.addCircuitProduct(circuitProduct)

        this.showToast(
          'Piezas eliminadas del circuito',
          'Las piezas del producto se han eliminado del circuito.',
          'success',
        )
      }
    },
    addPiecesToCircuit() {
      const isSameLocation = piece => piece.Location === this.provitionalProduct.pieces[0].Location
      const sameProvitioanlLocation = this.provitionalProduct.pieces.every(isSameLocation)
      const sameCircuitLocation = this.circuitPieces.every(isSameLocation)

      let sameLocationLoadedList = true

      if (this.loadedPieces.length > 0 && this.circuitPieces.length > 0) {
        sameLocationLoadedList = this.loadedPieces.every(isSameLocation)
      }

      if (sameProvitioanlLocation && sameCircuitLocation && sameLocationLoadedList) {
        const circuitProduct = { ...this.product }
        circuitProduct.pieces = this.provitionalProduct.pieces.map(piece => ({ ...piece }))

        for (let index = 0; index < circuitProduct.pieces.length; index += 1) {
          circuitProduct.pieces[index].vgtSelected = false
        }

        circuitProduct.Qty = circuitProduct.pieces.length
        this.addCircuitProduct(circuitProduct)

        this.showToast(
          'Piezas en circuito',
          'Las piezas seleccionadas se han agregado al circuito.',
          'success',
        )
      } else {
        this.showToast(
          'Ubición incorrecta',
          'Las piezas seleccionadas no pertenecen a la misma ubicación.',
          'warning',
        )
      }
    },
    deletePiecesFromCircuit() {
      const circuitProduct = { ...this.product }
      const piecesToRemove = this.provitionalProductToDelete.pieces.map(piece => ({ ...piece }))

      const filteredPieces = circuitProduct.pieces.filter(p => !piecesToRemove.find(pr => pr.IdPiece === p.IdPiece))

      circuitProduct.pieces = filteredPieces
      circuitProduct.Qty = filteredPieces.length
      this.product.pieces = [...filteredPieces]
      this.product.Qty = this.product.pieces.length

      this.addCircuitProduct(circuitProduct)

      const circuitProductToDelete = { ...this.product }
      circuitProductToDelete.pieces = piecesToRemove
      circuitProductToDelete.pieces.forEach(piece => {
        const unseletedPiece = piece
        unseletedPiece.vgtSelected = false
      })
      this.addDeletedProduct(circuitProductToDelete)

      const provitionalProduct = { ...this.product }
      provitionalProduct.pieces = []
      this.addProvitionalProductToDelete(provitionalProduct)

      this.showToast(
        'Piezas eliminadas del circuito',
        'Las piezas seleccionadas se han eliiminado del circuito.',
        'success',
      )
    },
    onShowCircuitsModal() {
      const isSameLocation = piece => piece.Location === this.provitionalProduct.pieces[0].Location
      const sameProvitioanlLocation = this.provitionalProduct.pieces.every(isSameLocation)

      if (sameProvitioanlLocation) {
        const pieces = this.provitionalProduct.pieces.map(piece => ({ ...piece }))
        this.$refs.circuitsModal.showModal(pieces)
      } else {
        this.showToast(
          'Ubición incorrecta',
          'Las piezas seleccionadas no pertenecen a la misma ubicación.',
          'warning',
        )
      }
    },
    reloadList() {
      this.$emit('reload-list')
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
