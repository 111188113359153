<template>
  <div>
    <!-- #region begin::Searcher and options for filtering -->
    <b-form-row>

      <!-- #region begin::Searcher and filters -->
      <b-col cols="10">
        <b-form-row align-h="start">

          <!-- #region::Searcher filter -->
          <b-col
            md="2"
            class="mt-1"
          >
            <BasicSearcher
              ref="basic-searcher"
              :callback="handleSearch"
              placeholder="Buscar productos"
            />
          </b-col>
          <!-- #endregion::Searcher filter -->

          <!-- #region::Brand filter -->
          <b-col
            md="2"
            class="mt-1"
          >
            <v-select
              v-model="selectedBrand"
              label="Name"
              class="style-chooser"
              placeholder="Marca"
              :options="getBrands"
              :disabled="isLoadingProducts"
            />
          </b-col>
          <!-- #endregion::Brand filter -->

          <!-- #region::Category filter -->
          <b-col
            md="2"
            class="mt-1"
          >
            <v-select
              v-model="selectedCategory"
              label="Name"
              class="style-chooser"
              placeholder="Subcategoría"
              :options="getCategories"
              :disabled="isLoadingProducts"
            />
          </b-col>
          <!-- #endregion::Category filter -->

          <!-- #region::Type filter -->
          <b-col
            md="2"
            class="mt-1"
          >
            <v-select
              v-model="selectedType"
              label="text"
              class="style-chooser"
              placeholder="Tipo"
              :options="getTypes"
              :disabled="isLoadingProducts"
            />
          </b-col>
          <!-- #endregion::Type filter -->

          <!-- #region::Location filter -->
          <b-col
            md="2"
            class="mt-1"
          >
            <v-select
              v-if="showLocationFilter"
              v-model="selectedLocation"
              label="text"
              class="style-chooser"
              placeholder="Ubicación"
              :options="getLocations"
              :disabled="isLoadingProducts"
            />
          </b-col>
          <!-- #endregion::Location filter -->

          <!-- #region::Date filter -->
          <b-col
            md="2"
            class="mt-1"
          >
            <b-form-datepicker
              id="example-datepicker"
              v-model="selectedDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              placeholder="Fecha"
              local="es"
              reset-button
            />
          </b-col>
          <!-- #endregion::Date filter -->

          <!-- #region::Payment status filter -->
          <b-col
            cols="2"
            class="mt-1"
          >
            <v-select
              v-model="selectedAvailability"
              label="text"
              class="style-chooser"
              placeholder="Disponibilidad"
              :options="getAvailabilities"
            />
          </b-col>
          <!-- #endregion::Payment status filter -->

          <!-- #region::Pieces status filter -->
          <b-col
            cols="2"
            class="mt-1"
          >
            <v-select
              v-model="SelectedPieceStatus"
              label="text"
              class="style-chooser"
              placeholder="Estatus"
              :options="getPiecesStatus"
            />
          </b-col>
          <!-- #endregion::Pieces status filter -->

          <!-- #region::Year filter -->
          <b-col
            cols="2"
            class="mt-1"
          >
            <v-select
              v-model="selectedYear"
              label="year"
              class="style-chooser"
              placeholder="Año"
              :options="getIncomesYears"
            />
          </b-col>
          <!-- #endregion::Year filter -->

          <!-- #region::Searcher filter -->
          <b-col
            v-if="canShowImportNumberSearcher"
            md="2"
            class="mt-1"
          >
            <BasicSearcher
              ref="import-number-searcher"
              placeholder="Buscar no. importación"
              :callback="handleSearchImportNumber"
              :waiting-time="2000"
              :add-key-down-event="true"
            />
          </b-col>
          <!-- #endregion::Searcher filter -->

        </b-form-row>
      </b-col>
      <!-- #endregion begin::Searcher and filters -->

      <b-col cols="2">
        <b-form-row>
          <b-col
            cols="12"
            md="auto"
            class="ml-auto mt-1"
          >

            <!-- #region::Download CSV File button -->
            <b-button
              variant="flat-success"
              class="csv-btn float-right"
              :disabled="isSendingEmail"
              @click="onSendingEmail"
            >
              <b-spinner
                v-if="isSendingEmail"
                class="mr-50"
                small
              />
              <feather-icon
                v-if="!isSendingEmail"
                icon="MailIcon"
                class="mr-50"
              />
              <span class="align-middle">Enviar reporte</span>
            </b-button>
            <!-- #endregion::Download CSV File button -->

          </b-col>
        </b-form-row>
      </b-col>

    </b-form-row>
    <!-- #endregion end::Searcher and options for filtering -->

  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import {
  BCol, BFormRow, BFormDatepicker, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'IncomesListFilters',
  components: {
    BCol,
    BButton,
    vSelect,
    BFormRow,
    BSpinner,
    BasicSearcher,
    BFormDatepicker,
  },
  props: {
    showOptionsButtons: {
      type: Boolean,
      default: false,
    },
    showLocationFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedDate: null,
      selectedType: null,
      selectedYear: null,
      selectedBrand: null,
      selectedCategory: null,
      selectedLocation: null,
      SelectedPieceStatus: null,
      selectedAvailability: null,

      userData: JSON.parse(localStorage.getItem('userData')),

      isSendingEmail: false,
    }
  },
  computed: {
    ...mapState('products', ['isLoadingProducts']),
    ...mapGetters({
      getBrands: 'brands/getBrands',
      getTypes: 'products/getTypes',
      getLocations: 'pieces/getLocations',
      getCategories: 'categories/getCategories',
      getPiecesStatus: 'pieces/getPiecesStatus',
      getIncomesYears: 'incomes/getIncomesYears',
      getAvailabilities: 'pieces/getAvailabilities',
      /**
       * Filtros globales
       */
      getFilteredYear: 'filters/getFilteredYear',
      getFilteredBrand: 'filters/getFilteredBrand',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredSubcategory: 'filters/getFilteredSubcategory',
      getFilteredProductType: 'filters/getFilteredProductType',
      getFilteredStatusPiece: 'filters/getFilteredStatusPiece',
      getFilteredAvailability: 'filters/getFilteredAvailability',
      getFilteredCreatedAtDate: 'filters/getFilteredCreatedAtDate',
      getFilteredPiecesLocation: 'filters/getFilteredPiecesLocation',
      getFilteredImportNumberSearchTerm: 'filters/getFilteredImportNumberSearchTerm',
    }),
    /**
     * Filtro de año (filtro en store)
     */
    filteredYear: {
      get() { return this.getFilteredYear },
    },
    /**
     * Marca filtrada (filtro en store)
     */
    filteredBrand: {
      get() { return this.getFilteredBrand },
    },
    /**
     * Término de búsqueda (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
    },
    /**
     * Subcategoría filtrada (filtro en store)
     */
    filteredSubcategory: {
      get() { return this.getFilteredSubcategory },
    },
    /**
     * Tipo de producto filtrado (filtro en store)
     */
    filteredProductType: {
      get() { return this.getFilteredProductType },
    },
    /**
     * Estatus de piezas filtrado (filtro en store)
     */
    filteredStatusPiece: {
      get() { return this.getFilteredStatusPiece },
    },
    /**
     * Disponibilidad seleccionada (filtro en store)
     */
    filteredAvailability: {
      get() { return this.getFilteredAvailability },
    },
    /**
     * Fecha de creación filtrada (filtro en store)
     */
    filteredCreatedAtDate: {
      get() { return this.getFilteredCreatedAtDate },
    },
    /**
     * Ubicación de piezas filtrada (filtro en store)
     */
    filteredPiecesLocation: {
      get() { return this.getFilteredPiecesLocation },
    },
    /**
     * Término de búsqueda para el número de importación (filtro en store)
     */
    filteredImportNumberSearchTerm: {
      get() { return this.getFilteredImportNumberSearchTerm },
      set(value) { this.setFilteredImportNumberSearchTerm(value) },
    },
    /**
     * Solo el adiminitrador puede ver el buscador de número de importación
     */
    canShowImportNumberSearcher() {
      return this.userData.Role === 'Administrador'
    },
  },

  /**
  * Carga información del estado
  *
  * @summary Si existe información relacionada con los filtros cargados en el store,
  * se cargan en el componente
  */
  mounted() {
    // Carga el término de búsqueda (filtro en store)
    if (this.filteredSearchTerm) {
      this.$refs['basic-searcher'].loadSearchTerm(this.filteredSearchTerm)
    }

    // Carga el término de búsqueda para el número de importación (filtro en store)
    if (this.filteredImportNumberSearchTerm) {
      this.$refs['import-number-searcher'].loadSearchTerm(this.filteredImportNumberSearchTerm)
    }

    // Carga la fecha de creación filtrada (filtro en store)
    this.selectedDate = this.filteredCreatedAtDate || null
    this.$watch('selectedDate', date => {
      this.$emit('on-filter', { type: 'date', value: date })
    })

    // Carga el año filtrado (filtro en store)
    this.selectedYear = this.filteredYear ? { year: this.filteredYear } : null
    this.$watch('selectedYear', year => {
      this.$emit('on-filter', { type: 'year', value: year !== null ? year.year : '' })
    })

    // Carga la marca filtrada (filtro en store)
    this.selectedBrand = this.filteredBrand ? { Name: this.filteredBrand } : null
    this.$watch('selectedBrand', brand => {
      this.$emit('on-filter', { type: 'brand', value: brand !== null ? brand.Name : '' })
    })

    // Carga la subcategoría filtrada (filtro en store)
    this.selectedCategory = this.filteredSubcategory ? { Name: this.filteredSubcategory } : null
    this.$watch('selectedCategory', category => {
      this.$emit('on-filter', { type: 'category', value: category !== null ? category.Name : '' })
    })

    // Carga el tipo de producto filtrado (filtro en store)
    this.selectedType = this.filteredProductType ? { text: this.filteredProductType } : null
    this.$watch('selectedType', type => {
      this.$emit('on-filter', { type: 'type', value: type !== null ? type.text : '' })
    })

    // Carga la ubicación de piezas filtrada (filtro en store)
    this.selectedLocation = this.filteredPiecesLocation ? { text: this.filteredPiecesLocation } : null
    this.$watch('selectedLocation', location => {
      this.$emit('on-filter', { type: 'location', value: location !== null ? location.text : '' })
    })

    // Carga el estatus de piezas filtrado (filtro en store)
    this.SelectedPieceStatus = this.filteredStatusPiece ? { text: this.filteredStatusPiece } : null
    this.$watch('SelectedPieceStatus', status => {
      this.$emit('on-filter', { type: 'status', value: status !== null ? status.text : '' })
    })

    // Carga la disponibilidad seleccionada
    this.selectedAvailability = this.filteredAvailability ? this.filteredAvailability : null
    this.$watch('selectedAvailability', availability => {
      this.$emit('on-filter', { type: 'availability', value: availability !== null ? availability.text : '' })
    })
  },

  methods: {
    ...mapActions({
      sendIncomesCSVFile: 'incomes/sendIncomesCSVFile',
      setFilteredImportNumberSearchTerm: 'filters/setFilteredImportNumberSearchTerm',
    }),
    /**
    * Evento del buscador básico
    *
    * @summary Emite un evento con el término de búsqueda y limpia los campos de selección
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearch(searchTerm) {
      this.selectedYear = null
      this.selectedDate = null
      this.selectedType = null
      this.selectedBrand = null
      this.selectedCategory = null
      this.selectedLocation = null
      this.SelectedPieceStatus = null
      this.selectedAvailability = null

      if (this.canShowImportNumberSearcher) {
        // Limpia el valor de número de importación del campo y del filtro global
        this.$refs['import-number-searcher'].loadSearchTerm('')
        this.setFilteredImportNumberSearchTerm('')
      }

      this.$emit('on-filter', { type: 'search', value: searchTerm })
    },
    handleSearchImportNumber(searchTerm) {
      this.$emit('on-filter', { type: 'importNumber', value: searchTerm })
    },

    /**
     * Envía un correo electrónico con el reporte de comisiones
     */
    async onSendingEmail() {
      try {
        this.isSendingEmail = true

        // Lanzar mensaje de error si no se han seleccionado los filtros obligatorios
        // if (!this.selectedRequiredFilters()) {
        //   this.showToast(
        //     'Error de validación',
        //     'Debe seleccionar tres filtros: Tipo de producto y al menos dos de los filtros restantes.',
        //     'danger',
        //   )
        //   return
        // }

        await this.sendIncomesCSVFile(this.loadParams())
        this.showToast(
          'Correo enviado',
          'El correo electrónico ha sido enviado correctamente.',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isSendingEmail = false
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        yearTerm: this.filteredYear,
        typeTerm: this.filteredProductType,
        brandTerm: this.filteredBrand,
        searchTerm: this.filteredSearchTerm,
        categoryTerm: this.filteredSubcategory,
        locationTerm: this.filteredPiecesLocation,
        statusPieceTerm: this.filteredStatusPiece,
        availabilityTerm: this.filteredAvailability,
        dateTerm: this.filteredCreatedAtDate,
        importNumberTerm: this.filteredImportNumberSearchTerm,
      }
    },

    /**
     * Activación de botón de envío de correo     *
     * @summary Verfica que el filtro de tipo de producto y el filtro de año estén
     * seleccionados (Obligatorios) y al menos uno de los filtros restantes.
     *
     * @returns {Boolean} - Indica si los filtros obligatorios están seleccionados
     */
    selectedRequiredFilters() {
      const filters = [
        this.filteredYear,
        this.filteredBrand,
        this.filteredSubcategory,
        this.filteredPiecesLocation,
        this.filteredStatusPiece,
        this.filteredAvailability,
        this.filteredCreatedAtDate,
        this.filteredImportNumberSearchTerm,
        this.filteredSearchTerm,
      ]

      const selectedFiltersCount = filters.reduce((count, filter) => (filter ? count + 1 : count), 0)

      return this.filteredProductType && selectedFiltersCount >= 2
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

[class*="collapse-"] {
  .card {
    .card-header {
      cursor: auto;
      padding: 1rem 2.8rem 1rem 1rem;
    }
  }
}

.style-chooser {
  background: #ffffff;
}

.col-padding {
  padding: 0 0.5rem 0 0.5rem;
}
</style>
