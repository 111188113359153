<template>
  <div>
    <!-- #region begin::Searcher and filters -->
    <div>
      <template v-if="isLoadingFiltersData">
        <BasicSkeleton height="120px" />
        <BasicSkeleton height="430px" />
      </template>
      <IncomesListFilters
        v-else
        :show-options-buttons="showOptionsButtons"
        @on-filter="handleFilter"
      />
    </div>
    <!-- #endregion begin::Searcher and filters -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingIncomes">
      <BasicSkeleton height="430px" />
    </template>
    <!-- #region::Skeleton -->

    <!-- #region begin::Products list -->
    <template v-else>
      <app-collapse>
        <IncomesCollapsableItem
          v-for="(product, index) in products"
          :key="index"
          :product="product"
        />
      </app-collapse>
    </template>
    <!-- #endregion end::Products list -->

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableProducts"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalProducts"
    />
    <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import IncomesListFilters from '@/modules/store/incomes/components/IncomesListFilters.vue'
import IncomesCollapsableItem from '@/modules/store/incomes/components/IncomesCollapsableItem.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  name: 'IncomesList',
  components: {
    AppCollapse,
    BasicSkeleton,
    BasicPaginator,
    IncomesListFilters,
    IncomesCollapsableItem,
  },

  props: {
    showOptionsButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lastPage: 0,
      products: [],
      statusTerm: '',
      groupDate: null,
      totalProducts: 0,
      isLoadingIncomes: false,
      isLoadingFiltersData: false,
    }
  },
  computed: {
    ...mapGetters({
      /**
       * Filtros globales
       */
      getFilteredYear: 'filters/getFilteredYear',
      getFilteredBrand: 'filters/getFilteredBrand',
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
      getFilteredSubcategory: 'filters/getFilteredSubcategory',
      getFilteredProductType: 'filters/getFilteredProductType',
      getFilteredStatusPiece: 'filters/getFilteredStatusPiece',
      getFilteredAvailability: 'filters/getFilteredAvailability',
      getFilteredCreatedAtDate: 'filters/getFilteredCreatedAtDate',
      getFilteredPiecesLocation: 'filters/getFilteredPiecesLocation',
      getFilteredImportNumberSearchTerm: 'filters/getFilteredImportNumberSearchTerm',
    }),
    /**
     * Filtro de año (filtro en store)
     */
    filteredYear: {
      get() { return this.getFilteredYear },
      set(value) { this.setFilteredYear(value) },
    },
    /**
     * Marca filtrada (filtro en store)
     */
    filteredBrand: {
      get() { return this.getFilteredBrand },
      set(value) { this.setFilteredBrand(value) },
    },
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    /**
     * Subcategoría filtrada (filtro en store)
     */
    filteredSubcategory: {
      get() { return this.getFilteredSubcategory },
      set(value) { this.setFilteredSubcategory(value) },
    },
    /**
     * Tipo de producto filtrado (filtro en store)
     */
    filteredProductType: {
      get() { return this.getFilteredProductType },
      set(value) { this.setFilteredProductType(value) },
    },
    /**
     * Estado de la pieza filtrado (filtro en store)
     */
    filteredStatusPiece: {
      get() { return this.getFilteredStatusPiece },
      set(value) { this.setFilteredStatusPiece(value) },
    },
    /**
     * Disponibilidad de la pieza filtrada (filtro en store)
     */
    filteredAvailability: {
      get() { return this.getFilteredAvailability },
      set(value) { this.setFilteredAvailability(value) },
    },
    /**
     * Fecha de creación filtrada (filtro en store)
     */
    filteredCreatedAtDate: {
      get() { return this.getFilteredCreatedAtDate },
      set(value) { this.setFilteredCreatedAtDate(value) },
    },
    /**
     * Ubicación de piezas filtrada (filtro en store)
     */
    filteredPiecesLocation: {
      get() { return this.getFilteredPiecesLocation },
      set(value) { this.setFilteredPiecesLocation(value) },
    },
    /**
     * Término de búsqueda para el número de importación (filtro en store)
     */
    filteredImportNumberSearchTerm: {
      get() { return this.getFilteredImportNumberSearchTerm },
      set(value) { this.setFilteredImportNumberSearchTerm(value) },
    },
    availableProducts() {
      return this.totalProducts > 0
    },
  },

  /**
  * Hook que se ejecuta cuando el componente es montado
  *
  * @summary Emite el evento cuando el componente ha sido montado
  */
  mounted() {
    this.$emit('product-list-mounted')
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Verifica que no se hayan cargado los filtros globales y carga la lista de productos
  */
  async created() {
    this.isLoadingFiltersData = true
    await this.loadIncomesYears()
    await this.loadFilterData()
    this.isLoadingFiltersData = false

    await this.loadIncomesListWithVerification()
  },
  methods: {
    ...mapActions({
      loadIncomes: 'incomes/getIncomes',
      loadIncomesYears: 'incomes/loadIncomesYears',
      loadBrandsWithoutPagination: 'brands/loadBrandsWithoutPagination',
      loadCategoriesWithoutPagination: 'categories/loadCategoriesWithoutPagination',
      /**
       * Filtros globales
       */
      setFilteredYear: 'filters/setFilteredYear',
      setFilteredBrand: 'filters/setFilteredBrand',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
      setFilteredSubcategory: 'filters/setFilteredSubcategory',
      setFilteredProductType: 'filters/setFilteredProductType',
      setFilteredStatusPiece: 'filters/setFilteredStatusPiece',
      setFilteredAvailability: 'filters/setFilteredAvailability',
      setFilteredCreatedAtDate: 'filters/setFilteredCreatedAtDate',
      setFilteredPiecesLocation: 'filters/setFilteredPiecesLocation',
      setFilteredImportNumberSearchTerm: 'filters/setFilteredImportNumberSearchTerm',
    }),

    /**
    * Evento de filtrado
    * @summary Atiende el evento de filtrado del compontente filter, asignando
    * términos de búsqueda en base a los campos de selección y el buscador así como
    * establecer los filtros globales en el store
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      switch (filter.type) {
        case 'date': this.filteredCreatedAtDate = filter.value
          break
        case 'year': this.filteredYear = filter.value
          break
        case 'brand': this.filteredBrand = filter.value
          break
        case 'category': this.filteredSubcategory = filter.value
          break
        case 'type': this.filteredProductType = filter.value
          break
        case 'status': this.filteredStatusPiece = filter.value
          break
        case 'availability': this.filteredAvailability = filter.value
          break
        case 'location': this.filteredPiecesLocation = filter.value
          break
        case 'importNumber': this.filteredImportNumberSearchTerm = filter.value
          break
        default: this.filteredSearchTerm = filter.value
          this.filteredCurrentPage = 1
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage()
          break
      }

      this.loadIncomesListWithVerification()
    },

    /**
    * Carga de elementos de la lista con verificación de paginado
    * @summary Carga la lista de elementos y verifica que la página actual contenga elementos
    */
    async loadIncomesListWithVerification() {
      await this.loadListIncomes()
      await this.verifyContenList()
    },

    /**
    * Evento de filtrado
    * @summary Evento del componente de filtrado. Devuelve los registros en base
    * al tipo de filtro y recarga la lista de traspasos
    * @param filter - Objeto con el tipo y valor del filtro
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      await this.loadListIncomes()
    },

    /**
    * Verificación de contenido
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.products.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableProducts) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadListIncomes()
        }
      }
    },

    /**
    * Carga de registros de tipo salidas
    * @summary ⁡⁢⁣⁡⁢⁢⁡⁣⁣⁡⁣⁢⁣⁡⁣⁢⁢⁡⁢⁢⁢‍Carga y establece los registros de salidas y los parámetros de paginación⁡⁡⁡⁡⁡⁡
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de cotizaciones⁡
    */
    async loadListIncomes() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingIncomes = true

        const inProducts = []

        const response = await this.loadIncomes(this.loadParams())
        response.data.data.products.data.forEach(item => {
          if (this.groupDate == null || this.groupDate !== item.Date) {
            const date = item.Date
            inProducts.push({
              [date]: [item],
            })
            this.groupDate = date
          } else if (this.groupDate === item.Date) {
            const date = item.Date
            inProducts[inProducts.length - 1][date].push(item)
          }
        })
        this.groupDate = null
        this.products = inProducts
        this.totalProducts = response.data.data.products.total
        this.lastPage = response.data.data.products.last_page

        this.isLoadingIncomes = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Información de filtros
    * @summary Carga la información de los filtros de salidas
    * @exception ⁡⁢⁣⁢Error de validación en la carga de registros de filtros de salidas ⁡
    */
    async loadFilterData() {
      try {
        await this.loadBrandsWithoutPagination()
        await this.loadCategoriesWithoutPagination()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.filteredPerPage,
        resource: this.resource,
        yearTerm: this.filteredYear,
        typeTerm: this.filteredProductType,
        brandTerm: this.filteredBrand,
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
        categoryTerm: this.filteredSubcategory,
        locationTerm: this.filteredPiecesLocation,
        statusPieceTerm: this.filteredStatusPiece,
        availabilityTerm: this.filteredAvailability,
        dateTerm: this.filteredCreatedAtDate,
        statusTerm: this.statusTerm,
        importNumberTerm: this.filteredImportNumberSearchTerm,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
