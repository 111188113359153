var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[(_vm.$ability.can('read', 'Product_images'))?_c('b-col',{attrs:{"lg":"1","md":"2"}},[_c('ProductCardSwiper',{attrs:{"title":_vm.product.Name,"images":_vm.product.images,"images-small":_vm.product.images.small,"images-full":_vm.product.images.full}})],1):_vm._e(),_c('b-col',{attrs:{"lg":"11","md":"10"}},[_c('b-row',[(_vm.$ability.can('read', 'Product_brand'))?_c('b-col',{attrs:{"md":"3"}},[_c('InformationBasicCard',{attrs:{"title":"Marca","tags":[{ value: _vm.product.name_brand, type: 'string' }]}})],1):_vm._e(),(_vm.$ability.can('read', 'Product_model'))?_c('b-col',{attrs:{"md":"3"}},[_c('InformationBasicCard',{attrs:{"title":"Modelo","tags":[{ value: _vm.product.Model, type: 'string' }]}})],1):_vm._e(),(_vm.$ability.can('read', 'Product_category'))?_c('b-col',{attrs:{"md":"3"}},[_c('InformationBasicCard',{attrs:{"title":"Categoría y subcategoría","tags":[{ value: ("Categoría: " + (_vm.product.TrueCategory ? _vm.product.TrueCategory.Name : 'Sin categoría')), type: 'string' },
                    { value: ("Subcategoría: " + (_vm.product.name_categorie)), type: 'string' }]}})],1):_vm._e(),(_vm.product.Type === 'Nuevo' && _vm.$ability.can('read', 'Product_code'))?_c('b-col',{attrs:{"md":"3"}},[_c('InformationBasicCard',{attrs:{"title":"Código interno","tags":[{ value: _vm.product.Code, type: 'string' }]}})],1):_vm._e(),(_vm.product.Type === 'Nuevo' && _vm.$ability.can('read', 'Product_item'))?_c('b-col',{attrs:{"md":"3"}},[_c('InformationBasicCard',{attrs:{"title":"Item","tags":[{ value: _vm.product.Item, type: 'string' }]}})],1):_vm._e(),(_vm.$ability.can('read', 'Product_color_paint') && _vm.$ability.can('read', 'Product_color_tapestry'))?_c('b-col',{attrs:{"md":"3"}},[_c('InformationBasicCard',{attrs:{"title":"Color pintura y tapiz","tags":[{ name: _vm.product.NamePaint, value: _vm.product.CodePaint, type: 'color' },
                    { name: _vm.product.NameTapestry, value: _vm.product.CodeTapestry, type: 'color' }]}})],1):_vm._e(),((_vm.product.Type !== 'Nuevo' && _vm.product.Type !== 'Remanufacturado') && _vm.$ability.can('read', 'Product_prices_list'))?_c('b-col',{attrs:{"md":"3"}},[(_vm.product.PriceLf && _vm.product.PriceDr)?_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[
              { value: _vm.product.PriceLf, prepend: 'L/F:', type: 'currency' },
              { value: _vm.product.PriceDr, prepend: 'D/R:', type: 'currency' },
              { value: _vm.product.MaxSuggestedPrice, prepend: 'Precio máximo:', type: 'currency-small' } ].concat( _vm.product.OfferPrice ? [{ value: _vm.product.OfferPrice, prepend: 'Oferta:', type: 'currency', style: 'color: #FF7766;' }] : [])}}):_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: 'Precio por definir', type: 'string' }]}})],1):_vm._e(),(_vm.canShowNormalPricesList && _vm.baseListPrice)?_c('b-col',{attrs:{"md":"3"}},[(_vm.product.PriceMin && _vm.product.PriceMax)?_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: _vm.product.PriceMin, prepend: 'Mín:', type: 'currency' },
                    { value: _vm.product.PriceMax, prepend: 'Máx:', type: 'currency' } ].concat( _vm.product.OfferPrice ? [{ value: _vm.product.OfferPrice, prepend: 'Oferta:', type: 'currency', style: 'color: #FF7766;' }] : [])}}):_c('InformationBasicCard',{attrs:{"title":"Precios","tags":[{ value: 'Precios por definir', type: 'string' }]}})],1):_vm._e(),(_vm.canShowPriceWholesaler && _vm.baseWholesalerPrice)?_c('b-col',{attrs:{"md":"3"}},[(_vm.product.PriceMinWholesaler && _vm.product.PriceMaxWholesaler)?_c('InformationBasicCard',{attrs:{"title":"Mayorista","tags":[{ value: _vm.product.PriceMinWholesaler, prepend: 'Mín:', type: 'currency' },
                    { value: _vm.product.PriceMaxWholesaler, prepend: 'Máx:', type: 'currency' }]}}):_c('InformationBasicCard',{attrs:{"title":"Mayorista","tags":[{ value: 'Precios por definir', type: 'string' }]}})],1):_vm._e()],1)],1),_c('b-button',{staticClass:"btn-icon rounded-circle ml-1 toggle-button",attrs:{"variant":_vm.visible ? 'flat-primary' : 'flat-secondary'},on:{"click":_vm.collapse}},[_c('feather-icon',{attrs:{"icon":_vm.iconToggle}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }