<template>
  <div>
    <!-- #region begin:title -->
    <b-form-row>
      <b-col cols="12">
        <h3>Entradas</h3>
      </b-col>
      <b-col
        cols="12"
        class="mb1"
      >
        <b-card-text>
          <!-- #region::Product list -->
          <IncomesList
            resource="products"
            :highlight-text="true"
            :show-options-buttons="true"
          />
          <!-- #endregion::Product list -->
        </b-card-text>
      </b-col>
    </b-form-row>
    <!-- #endregion end:title -->

  </div>
</template>

<script>
import {
  BCardText, BFormRow, BCol,
} from 'bootstrap-vue'

import IncomesList from '@/modules/store/incomes/components/IncomesList.vue'

export default {
  components: {
    BCol,
    BFormRow,
    BCardText,
    IncomesList,
  },
  data() {
    return {
      selectedTabIndex: 0,
    }
  },
  methods: {},
}
</script>
