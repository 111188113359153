<template>
  <b-card
    no-body
    class="shadow-none"
  >
    <b-card-body class="m-0 p-0">
      <slot>
        <IncomesCollapsableProduct
          v-for="(product, index) in productList"
          :key="product.IdProduct"
          :product="product"
          :is-visible="index == 0 ? true : false"
          :highlight-text="highlightText"
          :show-pieces-list="true"
          :origin-product-id="originProductId"
          :load-circuit-products="loadCircuitProducts"
          :selectable-pieces-list="selectablePiecesList"
        />
      </slot>
    </b-card-body>

  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import IncomesCollapsableProduct from '@/modules/store/incomes/components/IncomesCollapsableProduct.vue'

export default {
  name: 'IncomesCollapsableOrder',
  components: {
    BCard,
    BCardBody,
    IncomesCollapsableProduct,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    highlightText: {
      type: Boolean,
      default: false,
    },
    originProductId: {
      type: Number,
      default: 0,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    selectablePiecesList: {
      type: Boolean,
      default: false,
    },
    productList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '',
      openOnHover: this.$parent.hover,
      iconToggle: 'ChevronDownIcon',
      productOrder: [],
      isLoadingProducts: false,
      resource: 'products',
    }
  },
  computed: {
    ...mapGetters({}),
    circuitProducts: {
      get() { return this.getCircuitProducts },
    },

  },
  async created() {
    this.collapseItemId = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    collapse() {
      this.visible = !this.visible
    },
    ...mapActions({}),
  },
}
</script>
