<template>
  <b-form-row>

    <!-- #region::Name router link -->
    <b-col
      cols="12"
      md="auto"
      class="align-self-center"
    >
      <h5
        class="font-weight-bolder"
      >
        <router-link
          v-if="id"
          :to="onOpenProductDetails()"
        >{{ name }}
        </router-link>
        <span v-else>{{ name }}</span>
      </h5>
    </b-col>
    <!-- #endregion::Name router link -->

    <!-- #region::Product type label -->
    <b-col
      v-if="$ability.can('read', 'Product_type')"
      cols="12"
      md="auto"
    >
      <b-alert
        :variant="getTypeProductStyle"
        :class="getTypeProductStyle"
        class="green-style"
        show
      >
        <div class="alert-body">
          <span><strong>{{ type }}</strong></span>
        </div>
      </b-alert>
    </b-col>
    <!-- #endregion::Product type label -->

    <!-- #region::Count label -->
    <b-col
      v-if="$ability.can('read', 'Product_qty')"
      cols="12"
      md="auto"
    >
      <b-alert
        variant="principal-style"
        class="principal-style"
        show
      >
        <div class="alert-body">
          <span><strong>Cantidad: {{ count }}</strong></span>
        </div>
      </b-alert>
    </b-col>
    <!-- #endregion::Count label -->

  </b-form-row>

</template>

<script>
import { mapActions } from 'vuex'
import {
  BCol, BAlert, BFormRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BAlert,
    BFormRow,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      default: null,
    },
  },
  computed: {
    /**
     * Determina el estilo del tag en base al tipo del producto
     */
    getTypeProductStyle() {
      switch (this.type) {
        case 'Nuevo':
          return 'green-style'
        case 'Usado':
          return 'orange-style'
        default:
          return 'gray-style'
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      setOriginProductRoute: 'products/setOriginProductRoute',
    }),
    onOpenProductDetails() {
      let route = null

      route = { name: 'incomes-details', params: { id: this.id } }

      return route
    },
  },
}
</script>
